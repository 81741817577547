import { Alert, Button, LinearProgress, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ApiService from "../../services/ApiService";
import AppFooter from "./AppFooter";
import React from "react";
import AppAppBar from "./AppAppBar";
import withRoot from "../withRoot";
import { useNavigate } from "react-router-dom";

const FileUpload: React.FC = () => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {}, []);

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setCurrentFile(selectedFiles?.[0]);
    setProgress(0);
    setUploading(false);
    setMessage("");
  };

  const upload = () => {
    setProgress(0);
    setUploading(true);
    if (!currentFile) return;
    var referenceId: null = null;

    ApiService.start()
      .then((response) => {
        referenceId = response.data.reference;

        return ApiService.upload(currentFile, response.data, (event: any) => {
          setProgress(Math.round((100 * event.loaded) / event.total));
        });
      })
      .then((response) => {
        console.log(response);
        let payload = { reference: referenceId };

        return ApiService.inspect(payload);
      })
      .then((response) => {
        console.log(response.data.csv_header);
        navigate("/mapping", {
          state: { columns: response.data.csv_header, reference: referenceId },
        });
      })
      .catch((err) => {
        setProgress(0);

        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Could not upload the File!");
        }

        setCurrentFile(undefined);
      });
  };

  return (
    <React.Fragment>
      <AppAppBar pageName={"File Upload"} />
      <Box component="section" sx={{ display: "flex", overflow: "hidden" }}>
        <Container
          sx={{ mt: 10, mb: 30, display: "flex", position: "relative" }}
        >
          <Box
            sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
          />

          <Container component="main" maxWidth="sm">
            <Box
              sx={{
                backgroundColor: "background.paper",
                border: "1px solid #333",
                padding: 5,
              }}
            >
              <Typography variant="h6">FILE UPLOAD</Typography>
              <br></br>
              <Typography textAlign={"left"} color="primary">
                Start by uploading your CSV file containing your transactions.
                <br></br> If you are using Excel or Google Sheets make sure to
                export as a CSV. This application does not currently support
                those formats.
              </Typography>
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label className="btn btn-default p-0">
                      <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        sx={{ padding: 2 }}
                      >
                        <input
                          type="file"
                          onChange={selectFile}
                          accept=".csv"
                          width="100%"
                        />
                      </Button>
                    </label>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      component="a"
                      disabled={!currentFile || uploading}
                      onClick={upload}
                      fullWidth
                      sx={{ mt: 3, mb: 2, minWidth: 200 }}
                    >
                      {uploading ? "Uploading" : "Upload"}
                    </Button>
                    {currentFile && (
                      <LinearProgress
                        variant="determinate"
                        color="primary"
                        value={progress}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {message && (
                      <Box>
                        <Alert severity="error">
                          {message}
                          <br></br>
                          <br></br>Please verify that your file has a header row
                          with titles and that there are not any rows before the
                          header. Excel files are not currently supported.{" "}
                        </Alert>{" "}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Container>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(FileUpload);
