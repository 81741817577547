import * as React from "react";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import Instructions from "./modules/views/Instructions";
import AppAppBar from "./modules/views/AppAppBar";
import withRoot from "./modules/withRoot";

function Index() {
  return (
    <React.Fragment>
      <AppAppBar pageName={""} />
      <ProductHero />
      <Instructions />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
