import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { useLocation, useNavigate } from "react-router-dom";
import AppFooter from "./AppFooter";
import React from "react";
import AppAppBar from "./AppAppBar";
import withRoot from "../withRoot";

const DATE_OPEN = "date_open";
const DATE_CLOSE = "date_close";
const LONG_TERM = "term";
const SYMBOL = "symbol";
const OPEN_NET = "open_net";
const CLOSE_NET = "close_net";
const QUANTITY = "quantity";

const DONT_USE = "Don't Use";

function FieldMapping() {
  const [mapping, setMapping] = useState<Map<string, string>>(new Map());
  const [usedAttributes, setUsedAttributes] = useState<Set<string>>(new Set());
  const [message, setMessage] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();

  const columns = location.state.columns;
  const reference = location.state.reference;

  // attributes to be mapped to from the user's file
  const standardAttributes = [
    DATE_OPEN,
    OPEN_NET,
    DATE_CLOSE,
    CLOSE_NET,
    SYMBOL,
    QUANTITY,
    LONG_TERM,
  ];
  const sMap = new Map();
  sMap.set(DATE_OPEN, { name: "Opening Date", required: true });
  sMap.set(DATE_CLOSE, { name: "Closing Date", required: false });
  sMap.set(LONG_TERM, { name: "Term (optional)", required: false });
  sMap.set(SYMBOL, { name: "Symbol", required: true });
  sMap.set(OPEN_NET, { name: "Opening Net Amount $", required: true });
  sMap.set(CLOSE_NET, { name: "Closing Net Amount $", required: true });
  sMap.set(QUANTITY, { name: "Quantity", required: true });

  var initialMapping = new Map();
  var initialAttributes = new Set<string>();
  const getAlternateName = (col: string) => {
    var name = col.split("-", 2)[1];
    var cleanName = name.toLowerCase();
    cleanName = cleanName.replace(/[$()]/g, "").trim(); // remove $ ( ) and trim
    cleanName = cleanName.replace("  ", " ");

    const altMap = new Map<string, string>([
      ["opening date", DATE_OPEN],
      ["start date", DATE_OPEN],
      ["opening", DATE_OPEN],
      ["purchase date", DATE_OPEN],
      ["date", DATE_OPEN],
      ["date acquired", DATE_OPEN],
      ["received date", DATE_OPEN],

      ["closing date", DATE_CLOSE],
      ["end date", DATE_CLOSE],
      ["closing", DATE_CLOSE],
      ["sale date", DATE_CLOSE],
      ["date", DATE_CLOSE],
      ["date sold", DATE_CLOSE],
      ["date of disposition", DATE_CLOSE],

      ["opening net amount", OPEN_NET],
      ["opening net", OPEN_NET],
      ["purchase cost", OPEN_NET],
      ["cost basis", OPEN_NET],
      ["cost", OPEN_NET],
      ["cost basis cb", OPEN_NET],
      ["cost basis usd", OPEN_NET],
      ["cost basisusd", OPEN_NET],
      ["cost basis cogs", OPEN_NET],
      ["cogs", OPEN_NET],
      ["total cost", OPEN_NET],
      ["cost or other basis", OPEN_NET],

      ["closing net amount", CLOSE_NET],
      ["closing net", CLOSE_NET],
      ["sale amount", CLOSE_NET],
      ["sale proceeds", CLOSE_NET],
      ["sales proceeds", CLOSE_NET],
      ["proceeds", CLOSE_NET],
      ["proceeds usd", CLOSE_NET],
      ["proceeds investments", CLOSE_NET],

      ["quantity", QUANTITY],
      ["qty", QUANTITY],
      ["number", QUANTITY],
      ["asset amount", QUANTITY],
      ["shares", QUANTITY],
      ["amount sold", QUANTITY],

      ["term", LONG_TERM],
      ["long term", LONG_TERM],

      ["symbol", SYMBOL],
      ["security", SYMBOL],
      ["security description", SYMBOL],
      ["currency name", SYMBOL],
      ["asset", SYMBOL],
      ["asset name", SYMBOL],
      ["coin", SYMBOL],
    ]);

    let foundMatch = false;
    altMap.forEach((value, key) => {
      if (cleanName === key && !initialAttributes.has(value)) {
        initialMapping.set(col, value);
        initialAttributes.add(value);
        foundMatch = true;
        return true;
      }
    });
    return foundMatch;
  };

  const setDefaultMapping = () => {
    if (columns) {
      columns.forEach((column: string) => {
        getAlternateName(column);
      });
      setMapping(initialMapping);
      setUsedAttributes(initialAttributes);
    }
  };

  useEffect(() => {
    setDefaultMapping(); // Call the method to set the default fields array
  }, [columns, setMapping, setUsedAttributes]);

  function handleMappingChange(columnName: string, attribute: string) {
    const prevAttribute = mapping.get(columnName);
    if (prevAttribute && prevAttribute !== attribute) {
      setUsedAttributes((usedAttributes) => {
        const newUsedAttributes = new Set(usedAttributes);
        newUsedAttributes.delete(prevAttribute);
        return newUsedAttributes;
      });
    }
    var newMap = new Map(mapping);
    newMap.set(columnName, attribute);

    setMapping(newMap);
    setUsedAttributes(
      (usedAttributes) => new Set([...usedAttributes, attribute]),
    );
    setMessage("");
  }

  function isFieldSet(name: string) {
    return Array.from(mapping.values()).includes(name);
  }

  function handleSave() {
    // check required fields are mapped
    for (const [key, value] of sMap) {
      if (value.required && !isFieldSet(key)) {
        setMessage(`${sMap.get(key).name} is required`);
        return;
      }
    }

    let data = {
      reference: reference,
      mapping: Object.fromEntries(mapping),
    };

    // post to API
    ApiService.convert(data)
      .then((response) => {
        navigate("/download", { state: { txf: response.data } });
      })
      .catch((err) => {
        const defaultMessage =
          "Error converting file. Please check your field mapping AND check your file for inconsistent data or formatting.";
        const message =
          err.response?.status === 400
            ? err.response?.data?.message || defaultMessage
            : defaultMessage;
        setMessage(message);
      });

    console.log(mapping);
  }

  useEffect(() => {
    if (message) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [message]);

  return (
    <React.Fragment>
      <AppAppBar pageName={"Field Mapping"} />
      <Box component="section" sx={{ display: "flex", overflow: "hidden" }}>
        <Container component="main" maxWidth="lg" sx={{ marginTop: "0px" }}>
          {message && (
            <Alert severity="error" variant="filled" sx={{ mb: 3, mt: 2 }}>
              {message}
            </Alert>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 4,
              alignItems: "flex-start",
            }}
          >
            {/* Left Column: Text and Dropdown Controls */}
            <Box sx={{ flex: 1 }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", mb: 1, mt: 2 }}>
                <Box sx={{ flex: "1 1 50%", pr: 2 }}>
                  <Typography variant="h6" sx={{ textAlign: "right" }}>
                    Column Name
                  </Typography>
                </Box>
                <Box sx={{ flex: "1 1 50%" }}>
                  <Typography variant="h6">TXF Field</Typography>
                </Box>
              </Box>
              {columns &&
                columns.map((column: string, index: string) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Box sx={{ flex: "1 1 50%", textAlign: "right", pr: 2 }}>
                      <Typography variant="body1">
                        {column.split("-", 2)[1]}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: "1 1 50%",
                        maxWidth: { xs: "50%", sm: "unset" }, // Half width on mobile
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          value={mapping.get(column) || DONT_USE}
                          onChange={(e) =>
                            handleMappingChange(column, e.target.value)
                          }
                        >
                          {standardAttributes.map((attribute) => (
                            <MenuItem
                              key={attribute}
                              value={attribute}
                              disabled={usedAttributes.has(attribute)}
                            >
                              {sMap.get(attribute).name}
                            </MenuItem>
                          ))}
                          <MenuItem value={DONT_USE}>Don't Use</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                ))}
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                sx={{ mt: 4, mb: 4 }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>

            {/* Right Column: Help Section */}
            <Box
              sx={{
                flex: 1,
                backgroundColor: "#f5f5f5",
                p: 3,
                borderRadius: 1,
                boxShadow: 1,
                gap: 4,
                alignItems: "flex-start",
                marginTop: 2,
              }}
            >
              <Typography variant="h6">Help</Typography>
              <Typography sx={{ mt: 1 }}>
                You will need to <b>map the columns</b> from your CSV file to
                the required fields needed to complete the TXF conversion. You
                will need to map <b>ALL</b> the required fields before saving.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                You may need to edit your CSV file to include any{" "}
                <b>missing required fields</b>. You may map any extra fields in
                your CSV file to "Don't Use" to have them ignored. You can use a
                value of 'various' when a trade has multiple dates.
              </Typography>
              <Typography sx={{ mt: 1 }}>
                If you need to specify whether a trade is long-term or
                short-term (usually when various dates are used), you can
                specify that with a Term column. Values of ['long', 'l', 'yes',
                1, true] will be interpreted as long-term trades. Any other
                value will signify a short-term trade.
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography sx={{ mt: 1 }}>
                The following fields are required and need to have a mapping:
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Opening Date"
                    secondary="The date the position was opened."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Opening Net Amount"
                    secondary="The net proceeds or cost to open the position."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Closing Date"
                    secondary="The date the position was closed."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Closing Net Amount"
                    secondary="The net proceeds or cost to close the position."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Symbol"
                    secondary="A symbol used to uniquely describe the asset."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Quantity"
                    secondary="The quantity of the asset."
                  />
                </ListItem>
              </List>
              <Typography sx={{ mt: 1 }}>
                The following fields are optional:
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Long Term"
                    secondary="You can add a column to distinguish long-term from short-term trades."
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Container>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(FieldMapping);
