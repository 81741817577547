import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import Typography from "@mui/material/Typography";

interface AppAppBarProps {
  pageName: string;
}

function AppAppBar({ pageName }: AppAppBarProps) {
  return (
    <div>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            color="primary"
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              color: "inherit",
            }}
          >
            <Link
              underline="none"
              color="inherit"
              href="/"
              sx={{ fontWeight: "bold", marginRight: 1 }}
            >
              EASYTXF
              {pageName && ` - ${pageName}`}
            </Link>
          </Typography>

          <Typography
            color="highlight"
            variant="h6"
            component="div"
            sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 0 }}
          >
            <Link
              underline="none"
              color="inherit"
              href="/donate"
              sx={{ fontWeight: "bold" }}
            >
              DONATE
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
