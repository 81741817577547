import { Box, Button, Container, styled, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AppFooter from "./AppFooter";
import AppAppBar from "./AppAppBar";
import withRoot from "../withRoot";

const backgroundImage = "/alexander-grey-8lnbXtxFGZw-unsplash-med.jpg";

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat-y",
  zIndex: -2,
});

function Download() {
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location.state);

  const txf = location.state.txf;

  function downloadFile() {
    // file object
    const file = new Blob([txf as BlobPart], { type: "text/plain" });
    // anchor link
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "easytxf-" + Date.now() + ".txf";
    // simulate link click
    document.body.appendChild(element);
    // Required for this to work in FireFox
    element.click();
  }

  return (
    <>
      <AppAppBar pageName="Download" />
      <Box
        component="section"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.8,
            filter: "brightness(40%)",
            zIndex: -1,
          }}
        />

        <Container
          maxWidth="sm"
          sx={{
            textAlign: "center",
            p: 4,
            backgroundColor: "#dbdbdb",
            border: "1px solid #333",
            borderRadius: 2,
            zIndex: 1,
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            TXF FILE CREATED
          </Typography>
          <Typography textAlign="left" sx={{ mb: 2 }}>
            You can download your file and import it into TurboTax to verify the
            transactions. Please double-check that everything is correct.
          </Typography>
          <Typography textAlign="left" variant="body2" sx={{ mb: 3 }}>
            <b>WARNING</b> It is possible that this web service may not produce
            the expected results. The provider cannot guarantee the accuracy or
            completeness of the results and shall not be held responsible for
            any damages or losses resulting from the use of the service. Users
            are advised to review the service's{" "}
            <a href="/terms">terms and conditions</a>.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            sx={{ mb: 3 }}
            onClick={downloadFile}
          >
            DOWNLOAD
          </Button>
          <Typography textAlign="left" sx={{ mb: 3 }}>
            If you found this website helpful, consider making a donation to
            support its development and maintenance. Thank you for your support!
          </Typography>
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <form
              action="https://www.paypal.com/donate"
              method="post"
              target="_top"
            >
              <input
                type="hidden"
                name="hosted_button_id"
                value="4BNC8E6CFQCFJ"
              />
              <input
                type="image"
                src="https://pics.paypal.com/00/s/YjZiNmEzZTUtMDA2YS00ZjBmLWFkZDQtNjFlOGFhMzE1Njg3/file.PNG"
                name="submit"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            fullWidth
            onClick={() => navigate("/get-started")}
          >
            START ANOTHER FILE
          </Button>
        </Container>
      </Box>
      <AppFooter />
    </>
  );
}

export default withRoot(Download);
