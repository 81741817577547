import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Markdown from "../components/Markdown";
import AppAppBar from "./AppAppBar";
import AppFooter from "./AppFooter";
import withRoot from "../withRoot";

const privacy = `
#PRIVACY POLICY

Effective Date: 01.15.25

EasyTXF ("we," "our," or "us") is committed to protecting the privacy of our users ("you," "your") while using our web-based application ("the Service"). This Privacy Policy explains how we collect, use, and safeguard your information.

**1. Information We Collect**

- **Uploaded Files:** When you upload a CSV file to our Service, we temporarily store the file to provide conversion services and assist with debugging if necessary.
- **Usage Data:** We collect non-identifiable usage data through third-party analytics services, including Google Analytics and Microsoft Clarity.
- **Error Data:** We use third-party JavaScript exception recording services to track errors and improve functionality.

**2. How We Use Your Information**

- **File Storage:** Files you upload are retained for a maximum of 7 days. This allows us to support debugging and assist you if you encounter issues.
- **Service Improvement:** Usage and error data help us optimize and enhance the Service.

**3. Data Sharing**

- **Third-Party Services:**
  - Google Analytics and Microsoft Clarity are used to analyze usage patterns.
  - Third-party error tracking services monitor and log exceptions.
- **No Sale of Data:** We do not sell, rent, or trade your information to third parties under any circumstances.

**4. Data Security**

We take the security of your data seriously. We implement industry best practices, including encryption, secure storage solutions, and access controls, to protect your information from unauthorized access, alteration, or destruction.

**5. Data Retention**

Uploaded files are stored for a maximum of 7 days and are automatically deleted thereafter. Usage and error data collected by third-party services are retained in accordance with their respective policies.

**6. Your Choices**

- **Analytics Opt-Out:** You can opt out of Google Analytics by using the [Google Analytics Opt-out Browser Add-on](https://tools.google.com/dlpage/gaoptout).
- **File Deletion Requests:** You may request the deletion of uploaded files prior to the 7-day retention period by contacting us.

**7. Hosting**

The Service is hosted in a public cloud, ensuring scalability and reliability while adhering to stringent data protection standards.

**8. Changes to This Privacy Policy**

We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated "Effective Date."

By using the Service, you agree to this Privacy Policy. Thank you for trusting us with your data.

If you have any questions please contact us.`;

function Privacy() {
  return (
    <React.Fragment>
      <AppAppBar pageName={"Privacy Policy"} />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Markdown>{privacy}</Markdown>
        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Privacy);
