import http, { s3UploadClient } from "../common/Http";

const upload = async (
  file: File,
  data: any,
  onUploadProgress: any,
): Promise<any> => {
  let formData = new FormData();

  for (var k in data.sig.fields) {
    formData.append(k, data.sig.fields[k]);
  }

  formData.append("file", file);

  return s3UploadClient
    .post(data.sig.url, formData, {
      onUploadProgress,
    })
    .catch((e) => {
      console.log(e);
    });
};

const start = async () => {
  return http.get("/start");
};

const inspect = async (data: any) => {
  return http.post("/inspect", data);
};

const convert = async (data: any) => {
  return http.post("/convert", data);
};

const ApiService = {
  upload,
  start,
  inspect,
  convert,
};

export default ApiService;
