import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    highlight: Palette["primary"];
  }
  interface PaletteOptions {
    highlight?: PaletteOptions["primary"];
  }
}

const rawTheme = createTheme({
  palette: {
    primary: {
      light: "#69696b",
      main: "#28282b",
      dark: "#1e1e1",
      contrastText: "#ffffff", // Ensure white text on dark backgrounds
    },
    secondary: {
      light: "#F8FFF5",
      main: "#4CAF50", // Modern green
      dark: "#388E3C", // Deep green for hover effects
      // main: '#66FF33',
      // dark: '#1FE05F',
      contrastText: "#000000", // Ensure dark text on light backgrounds
    },
    highlight: {
      light: "#A8D5BA", // Muted green for highlights or buttons
      main: "#4CAF50", // Modern green
      dark: "#388E3C", // Deep green for hover effects
      contrastText: "#FFFFFF", // White text on highlight buttons
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    },
    text: {
      primary: "#333", // Standard text color
      secondary: grey[700], // Subtle text color
    },
    background: {
      default: "#ffffff",
      paper: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: "'Roboto Condensed', sans-serif",
};

const theme = {
  ...rawTheme,
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
      "@media (max-width:600px)": {
        fontSize: 48, // Responsive font size
      },
    },
    h2: {
      ...fontHeader,
      fontSize: 48,
      "@media (max-width:600px)": {
        fontSize: 36,
      },
    },
    h3: {
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      fontSize: 18,
    },
    body1: {
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
      color: grey[600],
    },
    overline: {
      fontSize: 10,
      textTransform: "uppercase",
      fontWeight: rawTheme.typography.fontWeightMedium,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
      styleOverrides: {
        root: {
          color: rawTheme.palette.primary.main,
          "&:hover": {
            color: rawTheme.palette.primary.dark,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
  },
};

export default theme;
