import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

const backgroundImage = "/photo-1586486855514-8c633cc6fd38.jpeg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#666", // Average color of the background image.
        backgroundPosition: "center",
        opacity: 0.6,
        filter: "brightness(40%)",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        CSV to TurboTax Conversion
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Easily convert your CSV file to a TurboTax TXF file.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="#instructions"
        sx={{ minWidth: 200 }}
      >
        Get Started
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Creating TXF files for FREE since October 2013
      </Typography>
      <Typography variant="overline" color="inherit" sx={{ mt: 2 }}>
        last update: 01.15.25
      </Typography>
    </ProductHeroLayout>
  );
}
