import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Button, List, ListItem, ListItemText, Stack } from "@mui/material";

function Instructions() {
  return (
    <Box
      component="section"
      id="instructions"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src="productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Stack spacing={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              px: 5,
            }}
          >
            <Typography variant="h6" sx={{ my: 5 }}>
              INSTRUCTIONS
            </Typography>
            <Typography variant="h5">
              You will need a{" "}
              <Box display="inline" fontWeight="fontWeightBold">
                CSV file{" "}
              </Box>
              exported from your financial institution or one that you have
              created. The file may contain extra columns but, it must contain
              the following columns. The columns names can also be different but
              the values should match the intent.
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={5}
              alignItems="center"
              sx={{ mt: 1, mb: 5 }}
            >
              {[
                [
                  {
                    primary: "Opening Date",
                    secondary: "date the position was open",
                  },
                  {
                    primary: "Opening Net Amount",
                    secondary: "The net proceeds or cost to open the position",
                  },
                ],
                [
                  {
                    primary: "Closing Date",
                    secondary: "date the position was closed",
                  },
                  {
                    primary: "Closing Net Amount",
                    secondary: "The net proceeds or cost to close the position",
                  },
                ],
                [
                  {
                    primary: "Symbol",
                    secondary: "symbol the uniquely describes the asset",
                  },
                  {
                    primary: "Quantity",
                    secondary: "The quantity of the asset",
                  },
                ],
              ].map((group, index) => (
                <List dense={true} key={index}>
                  {group.map((item, idx) => (
                    <ListItem key={idx}>
                      <ListItemText
                        primary={item.primary}
                        secondary={item.secondary}
                      />
                    </ListItem>
                  ))}
                </List>
              ))}
            </Stack>
            <Typography variant="h5" component="div">
              You can also optionally use{" "}
              <Box display="inline" fontWeight="fontWeightBold">
                "various"
              </Box>{" "}
              for any dates in cases where there are several dates associated
              with the transaction. When doing so you should also add a column
              to distinguish{" "}
              <Box display="inline" fontWeight="fontWeightBold">
                "long term"
              </Box>{" "}
              transactions or they will be treated as short term.
            </Typography>
          </Box>
          <Box textAlign="center">
            <Button
              color="secondary"
              variant="contained"
              size="large"
              component="a"
              href="get-started"
              sx={{ minWidth: 200 }}
            >
              Next
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

export default Instructions;
