import axios from "axios";

export default axios.create({
  baseURL: "https://20kl2vppea.execute-api.us-east-1.amazonaws.com",
  headers: {
    "Content-type": "application/json",
  },
});

export const s3UploadClient = axios.create({
  headers: {
    "Content-type": "multipart/form-data",
  },
  withCredentials: false,
});

export interface EmailRequest {
  name: string;
  email: string;
  message: string;
}
