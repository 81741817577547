import CssBaseline from "@mui/material/CssBaseline";
import Home from "./Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Privacy from "./modules/views/Privacy";
import Terms from "./modules/views/Terms";
import FileUpload from "./modules/views/FileUpload";
import Donations from "./modules/views/Donations";
import FieldMapping from "./modules/views/FieldMapping";
import Download from "./modules/views/Download";
import ContactUs from "./modules/views/Contact";

const router = createBrowserRouter(
  [
    { path: "/", element: <Home /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/terms", element: <Terms /> },
    { path: "/mapping", element: <FieldMapping /> },
    { path: "/download", element: <Download /> },
    { path: "/get-started", element: <FileUpload /> },
    { path: "/donate", element: <Donations /> },
    //{ path: "/contact", element: <ContactUs /> }
  ],
  // {
  //   future: {
  //     v7_relativeSplatPath: true, // Enable the future flag
  //   },
  // }
);

// app.post('/api/send-email', async (req: Request<{}, {}, EmailRequest>, res: Response) => {
//     const { name, email, message } = req.body;

//     if (!name || !email || !message) {
//         return res.status(400).json({ success: false, message: "All fields are required." });
//     }

//     try {
//         const transporter = nodemailer.createTransport({
//             service: 'gmail',
//             auth: {
//                 user: 'your-email@gmail.com',
//                 pass: 'your-email-password',
//             },
//         });

//         const mailOptions = {
//             from: email,
//             to: 'your-email@gmail.com',
//             subject: `Contact Us Message from ${name}`,
//             text: message,
//         };

//         await transporter.sendMail(mailOptions);
//         res.json({ success: true });
//     } catch (error: any) {
//         res.status(500).json({ success: false, message: error.message });
//     }
// });

function App() {
  return (
    <div>
      <CssBaseline />
      <RouterProvider router={router} />{" "}
      {/* Use RouterProvider with the router */}
    </div>
  );
}

export default App;
