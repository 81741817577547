import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "../components/Typography";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      Copyright&nbsp;
      <Link color="inherit" href="https://rodwhisnant.com">
        Rod Whisnant
      </Link>{" "}
      {new Date().getFullYear()}
      &nbsp; - All Rights Reserved
    </React.Fragment>
  );
}

const iconStyle = {
  width: 50,
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mr: 1,
  "&:hover": {
    bgcolor: "highlight.contrastText",
  },
};

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: "highlight.dark",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: { xs: 2, sm: 0 },
          }}
        >
          <Box
            component="a"
            href="https://twitter.com/rodwhisnant"
            sx={iconStyle}
          >
            <img src="/x-logo.png" alt="X (formerly Twitter)" />
          </Box>
          <Box>
            <Copyright />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "center", sm: "flex-end" },
            alignItems: "center",
          }}
        >
          <Box sx={{ ml: 2 }} color="primary">
            <Link href="/terms" color="inherit">
              Terms of Use
            </Link>
            &nbsp;&nbsp;
            <Link href="/privacy" color="inherit">
              Privacy Policy
            </Link>
          </Box>
        </Box>
      </Box>
    </Typography>
  );
}
